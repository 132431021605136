import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { EnvConfigProvider } from "../hooks/useEnvConfig";
import store from "src/reduxStore";
import { START_PATH } from "src/webapp_config";
import AppNoRouter from "./AppNoRouter";

function App(props) {
    return (
        <EnvConfigProvider>
            <Router basename={START_PATH}>
                <Provider store={store}>
                    <AppNoRouter {...props} />
                </Provider>
            </Router>
        </EnvConfigProvider>
    );
}

export default App;
